<template>
  <div>
    <!-- <van-cell-group>
      <van-cell title="头像" is-link>
        <van-image class="avatar" round :src="json.headimgurl" />
      </van-cell>
      <van-cell title="昵称" is-link :value="json.nickname"></van-cell>
    </van-cell-group> -->

    <van-row v-for="(item, i) in goodsList" :key="i">
      <van-col span="8">{{ item.realPrice }}</van-col>
      <van-col span="8">{{ item.description }}</van-col>
      <van-col span="8">{{ item.name }}</van-col>
    </van-row>

    <!--选择支付方式-->
    <van-radio-group :value="paymentMode" change="onChange">
      <van-cell-group>
        <van-cell title="微信支付" clickable @click="paymentMode = 'WECHAT'">
          <van-radio slot="right-icon" name="WECHAT" />
        </van-cell>
        <van-cell title="支付宝支付" clickable @click="paymentMode = 'ALI'">
          <van-radio slot="right-icon" name="ALI" />
        </van-cell>
      </van-cell-group>
    </van-radio-group>
    <!--支付按钮使用layOut布局-->
    <van-row>
      <van-col span="6" />
      <van-col span="12"
        ><van-button
          type="primary"
          size="large"
          style="margin-top: 20px"
          @click="pay"
          >确认支付</van-button
        ></van-col
      >
      <van-col span="6" />
    </van-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      json: {}, //重定向参数对象
      paymentMode: "WECHAT",
      goodsList: [],
    };
  },
  methods: {
    //加载商品列表
    queryGoods() {
      this.$axios
        .get(`${this.$base}/management/commodity?type=MEMBER`)
        .then((res) => {
          if (res.code === 200) {
            console.log(res.data);
            res.data.forEach((item) => {
              //只展示担任会员开通
              if (item.pattern === 1) {
                this.goodsList.push(item);
              }
            });
          }
        });
    },
    //初始化微信api
    generatorWxApi() {
      this.$axios
        .post(`${this.$base}/farbun/weChatOfficialAccount/getJSSdkConfig`, {
          url: window.location.href.split("#")[0],
        })
        .then((res) => {
          if (res.code === 200) {
            window.wx.config({
              debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: res.data.appId, // 必填，公众号的唯一标识
              timestamp: res.data.timestasmp, // 必填，生成签名的时间戳
              nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
              signature: res.data.signature, // 必填，签名
              jsApiList: ["chooseWXPay"], // 必填，需要使用的JS接口列表
            });
            window.wx.ready(() => {
              // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
              window.wx.checkJsApi({
                jsApiList: ["chooseWXPay"],
                success: function (res) {
                  console.log(res);
                },
                fail: function (res) {
                  console.log(res);
                },
              });
            });
            window.wx.error((res) => {
              // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
              console.log("api初始化失败:" + res);
            });
          }
        });
    },
    //使用微信支付
    pay() {
      //调起支付接口
      this.$axios
        .post(`${this.$baseUrl}/fission/wechat/generateMiniPay`, {
          account: this.userInfo.phoneNumber,
          nickName: "",
          openId: this.userInfo.openid,
          unionId: this.userInfo.unionid,
          goodId: this.goodsData.id,
          customerId: this.userInfo.customerId,
        })
        .then((data) => {
          var res = data;
          if (res.code === "200") {
            window.wx.chooseWXPay({
              timestamp: res.data.timeStamp + "", // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
              nonceStr: res.data.nonceStr, // 支付签名随机串，不长于 32 位
              package: "", // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
              signType: "", // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
              paySign: "", // 支付签名
              success: function (res) {
                // 支付成功后的回调函数
                console.log(res);
              },
            });
          }
        });
    },
  },
  mounted() {
    this.json = JSON.parse(this.$route.query.json);
    this.generatorWxApi();
    this.queryGoods();
  },
};
</script>
<style scoped>
.avatar {
  width: 30px;
  height: 30px;
}
</style>